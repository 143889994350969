.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }

  .btn-info:focus {
    animation: btn-info 2s cubic-bezier(.08, .82, .17, 1);
  }
  
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
  }

  .btn-info:active, .btn-info.active {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
    animation: none;
  }

  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }

  .btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
  }

  .btn-outline-info:focus {
    animation: btn-info 2s cubic-bezier(.08, .82, .17, 1);
  }
  
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:active, .btn-outline-info.active {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    animation: none;
  }

  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
  }

  @keyframes btn-info {
    0% {
        box-shadow: 0 0 0 0 rgba(58, 176, 195, 1);
    }
    100% {
        box-shadow: 0 0 0 6px rgba(58, 176, 195, 0);
    }
}