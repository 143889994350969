.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-secondary:focus {
    animation: btn-secondary 2s cubic-bezier(.08, .82, .17, 1);
}
  
.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}
  
.btn-secondary:active, .btn-secondary.active {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
    animation: none;
}

.btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
  }

  .btn-outline-secondary:focus {
    animation: btn-secondary 2s cubic-bezier(.08, .82, .17, 1);
  }
  
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }

  .btn-outline-secondary:active, .btn-outline-secondary.active {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    animation: none;
  }
  
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent;
  }


@keyframes btn-secondary {
    0% {
        box-shadow: 0 0 0 0 rgba(130, 138, 145, 1);
    }
    100% {
        box-shadow: 0 0 0 6px rgba(130, 138, 145, 0);
    }
}