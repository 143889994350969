.list-block-2 > li {
  width: 50%;
}

.list-block-3 > li {
  width: 33.333333%;
}

.list-block-4 > li {
  width: 25%;
}

.list-block-5 > li {
  width: 20%;
}

.list-block-6 > li {
  width: 16.666667%;
}

@media (max-width: 1199.98px) {
  .list-block-2-md > li {
    width: 50%;
  }
  
  .list-block-3-md > li {
    width: 33.333333%;
  }
  
  .list-block-4-md > li {
    width: 25%;
  }
  
  .list-block-5-md > li {
    width: 20%;
  }

  .list-block-6-md > li {
    width: 16.666667%;
  }
}

@media (max-width: 991.98px) {
  .list-block-2-sm > li {
    width: 50%;
  }
  
  .list-block-3-sm > li {
    width: 33.333333%;
  }

  .list-block-4-sm > li {
    width: 25%;
  }

  .list-block-5-sm > li {
    width: 20%;
  }
  
  .list-block-6-sm > li {
    width: 16.666667%;
  }
}