html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

html {
  color: #333;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}