.btn-warning {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
  }

  .btn-warning:focus {
    animation: btn-warning 2s cubic-bezier(.08, .82, .17, 1);
  }
  
  .btn-warning:hover {
    color: #fff;
    background-color: #e0a800;
    border-color: #d39e00;
  }
  
  .btn-warning:active, .btn-warning.active {
    color: #fff;
    background-color: #d39e00;
    border-color: #c69500;
    animation: none;
  }

  .btn-warning.disabled, .btn-warning:disabled {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
  }

  .btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107;
  }

  .btn-outline-warning:focus {
    animation: btn-warning 2s cubic-bezier(.08, .82, .17, 1);
  }
  
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
  }

  .btn-outline-warning:active, .btn-outline-warning.active {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
    animation: none;
  }
  
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
  }
  
  @keyframes btn-warning {
    0% {
        box-shadow: 0 0 0 0 rgba(222, 170, 12, 1);
    }
    100% {
        box-shadow: 0 0 0 6px rgba(222, 170, 12, 0);
    }
}