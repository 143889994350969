.des {
  line-height: 30px;
  color: #666;
}

.content {
  margin: 12px 0;
  border: 1px solid #ccc;
}

.inputdemo {
  width: 100%;
  height: 30px;
}
.inputdemo.sm {
  height: 24px;
}
.inputdemo.lg {
  height: 36px;
}
:global(.form-error) .inputdemo {
  border: 1px solid red;
}