[class*=form-col-] {
    margin: 0 -6px;
}

[class*=form-col-] .form-item {
    padding-right: 6px;
    padding-left: 6px;
}

.form-col-2 .form-item {
    float: left;
    width: 50%;
}

.form-col-3 .form-item {
    float: left;
    width: 33.333333%;
}

.form-col-4 .form-item {
    float: left;
    width: 25%;
}

.form-col-5 .form-item {
    float: left;
    width: 20%;
}

@media (max-width: 1199.98px) {
    .form-col-2-md .form-item {
        float: left;
        width: 50%;
    }
    
    .form-col-3-md .form-item {
        float: left;
        width: 33.333333%;
    }
    
    .form-col-4-md .form-item {
        float: left;
        width: 25%;
    }
    
    .form-col-5-md .form-item {
        float: left;
        width: 20%;
    }
}

@media (max-width: 991.98px) {
    .form-col-2-sm .form-item {
        float: left;
        width: 50%;
    }
    
    .form-col-3-sm .form-item {
        float: left;
        width: 33.333333%;
    }
    
    .form-col-4-sm .form-item {
        float: left;
        width: 25%;
    }
    
    .form-col-5-sm .form-item {
        float: left;
        width: 20%;
    }
}