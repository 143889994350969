.radio-disabled .radio-input {
  cursor: not-allowed;
}

.radio-disabled .radio-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  cursor: not-allowed;
}

.radio-disabled .radio + * {
  color: #c6c6c6;
  cursor: not-allowed;
}

.radio-disabled .radio-inner::after {
  background-color: #c9c9c9;
}